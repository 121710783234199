import React from "react";
import "katex/dist/katex.min.css";
import WorksPageBaseLayout from "./WorksPageBaseLayout";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import PageTitle from "./PageTitle";
import { getMetaTags, topLevelRoutes } from "../Utils";
import { Link } from "gatsby";
require("katex/dist/katex.min.css");

const WorksPage = ({ data: { mdx, site } }) => {
  const metaTags = getMetaTags({
    title: `${mdx.frontmatter.title} | ${site.siteMetadata.title}`,
    siteUrl: site.siteMetadata.siteUrl,
    slug: mdx.fields.slug,
    summary: mdx.frontmatter.summary,
  });
  const tableOfContentItems = mdx.tableOfContents.items.map(
    ({ url, title }) => (
      <li className="nav-item" key={url}>
        <Link to={`/${topLevelRoutes.works}${url}`}>{title} </Link>
      </li>
    )
  );
  const tableOfContent = (
    <ul className="d-md-none nav flex-column align-items-left">
      {" "}
      {tableOfContentItems}{" "}
    </ul>
  );
  return (
    <WorksPageBaseLayout>
      {metaTags}
      <PageTitle>{mdx.frontmatter.title}</PageTitle>
      {tableOfContent}
      <MDXRenderer>{mdx.body}</MDXRenderer>
    </WorksPageBaseLayout>
  );
};
export const pageQuery = graphql`
  query WorksPage($id: String) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    mdx(id: { eq: $id }) {
      id
      body
      fields {
        slug
      }
      tableOfContents(maxDepth: 2)
      frontmatter {
        title
      }
    }
  }
`;

export default WorksPage;
