import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { MDXProvider } from "@mdx-js/react";
import Sidebar from "./Sidebar";
import { mainContentId } from "../../buildConstants";
import CodeBlock from "./CodeBlock";
import { Link } from "gatsby";
import styled from "styled-components";
import { topLevelRoutes } from "../Utils";

require("../custom.scss");

const MyBlockQuote = props => (
  <blockquote className="blockquote text-center" {...props} />
);

const TOCItem = ({ text, fragment }) => (
  <li className="nav-item">
    <Link to={`/${topLevelRoutes.works}#${fragment}`}> {text}</Link>
  </li>
);

const components = {
  blockquote: MyBlockQuote,
  code: CodeBlock,
};

const BaseLayout = ({ children }) => {
  return (
    <MDXProvider components={components}>
      <a href={`#${mainContentId}`} className="sr-only sr-only-focusable">
        Skip to main content
      </a>
      <div className="container">
        <header className="row bg-light">
          <Header useWideLeftColumn={true} />
        </header>
        <div className="row justify-content-between">
          <div className="d-none d-md-block col-md-4">
            <aside className="sticky-top pt-4">
              <ul className="nav flex-column align-items-center d-flex">
                <TOCItem
                  text="Employer Projects"
                  fragment="employer-projects"
                />
                <TOCItem
                  text="Personal Projects"
                  fragment="personal-projects"
                />
                <TOCItem
                  text="Journal Papers"
                  fragment="peer-reviewed-journal-publications"
                />
                <TOCItem text="College Projects" fragment="college-projects" />
                <TOCItem
                  text="High School Projects"
                  fragment="high-school-projects"
                />
                <TOCItem
                  text="Miscellaneous"
                  fragment="miscellaneous"
                />
              </ul>
              <Sidebar setStickyTop={false} />
            </aside>
          </div>
          <main className="col-xs-12 col-md-8" id={mainContentId}>
            {children}
          </main>
        </div>
        <footer className="row">
          <Footer />
        </footer>
      </div>
    </MDXProvider>
  );
};

export default BaseLayout;
